<template>

  <el-container class="login">
    <el-header>

    </el-header>
    <el-main>
      <div class="main-left"></div>
      <el-form class="loginForm" :model="loginForm" ref="loginForm" :rules="loginRules" :inline="false" size="medium">
        <el-form-item>
          <div class="loginTitle">系统登录</div>
        </el-form-item>

        <el-form-item label="" prop='username'>
          <el-input v-model="loginForm.username" placeholder="请输入帐号">
            <i slot="prepend" class="fa fa-user"></i>
          </el-input>
        </el-form-item>

        <el-form-item label="" prop='password'>
          <el-input show-password v-model="loginForm.password" placeholder="请输入密码">
            <i slot="prepend" class="fa fa-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop='code'>
          <el-row :gutter="20">
            <el-col :span="16">
              <el-input v-model="loginForm.code" placeholder="请输入验证码">
                <i slot="prepend" class="fa fa-get-pocket"></i>
              </el-input>
            </el-col>
            <el-col :span="8">
              <img :src='imgSrc' @click="getImage" class="images" />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button class="btn" type="primary" @click="commitBtn()" size="default" @keyup.enter.native="qw">登录</el-button>

            </el-col>
            <el-col :span="12">
              <el-button class="btn" type="primary" @click="resetBtn()" size="default">重置</el-button>

            </el-col>
          </el-row>

        </el-form-item>

      </el-form>
      <div class="main-right"></div>
    </el-main>
    <el-footer>
      <el-card class="box-card" shadow="always">
        Copyright © 2022 - 2024 . CCKJ.All Rights Reserved  <span style="color:#409EFF;font-size:13px">黔ICP备20001382号</span>
      </el-card>
    </el-footer>
  </el-container>

</template>

<script>
import { login } from "../api/login";
import { getImageApi } from "../api/user";
// import Cookies from "js-cookie";
import { setToken, setUserId } from "@/utils/auth";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuids: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "change", message: "请输入帐号" },
        ],
        password: [
          { required: true, trigger: "change", message: "请输入密码" },
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }],
      },
      imgSrc: "",
    };
  },
  created() {
    this.getImage();
    var _this = this;
    document.addEventListener("keydown", _this.watchEnter);
  },
  methods: {
    watchEnter(e) {
      var keyNum = window.event ? e.keyCode : e.which; //获取被按下的键值
      //判断如果用户按下了回车键（keycody=13）
      if (keyNum == 13) {
        this.commitBtn();
      }
    },

    // 获取验证码图片
    async getImage() {
      let res = await getImageApi();
      this.loginForm.uuids = res.data.uuids;
      this.imgSrc = res.data.img;
      
    },
    commitBtn() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          let res = await login(this.loginForm);

          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            let datas = res.data;
            setToken(datas.token);
            setUserId(datas.userInfo.id);
            sessionStorage.setItem("truename", datas.userInfo.truename);
            this.$router.push({
              path: "/home",
            });
          } else {
            this.$message.error(res.data.msg);
            this.getImage();

            return;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetBtn() {
      this.$refs.loginForm.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  .el-header {
    display: flex;
    // justify-content: center;
    align-items: center;
    -webkit-background-clip: text;
    background: linear-gradient(
      to right,
      red,
      orange,
      yellow,
      green,
      cyan,
      purple
    );
    font-size: 20px;
    animation: hue 3s infinite;
    -webkit-text-fill-color: transparent;

    @keyframes hue {
      0% {
        filter: hue-rotate(0deg);
      }
      100% {
        filter: hue-rotate(360deg);
      }
    }
    background: url("~@/assets/666.png") center no-repeat;
  }
  .el-main {
    height: 100%;
    display: flex;
    // justify-content: flex-end;
    justify-content: center;
    align-items: center;
    // background: url("~@/assets/3.jpg") center no-repeat;
    background-size: 100% 100%;
    .loginForm {
      width: 350px;
      height: 300px;
      box-shadow: 0 0 25px #cac6c6;
      border-radius: 10px;
      padding: 20px 35px;
      // margin-right: 200px;
    }
    .main-left{
      width: 350px;
      height: 300px;
      // background: url("~@/assets/.jpg") center no-repeat;
      background-size: cover;
      padding: 20px 35px;
      border-radius: 10px;
    }
    .main-right{
      width: 350px;
      height: 300px;
      // background: url("~@/assets/.jpg") center no-repeat;
      background-size: cover;
      padding: 20px 35px;
      border-radius: 10px;
    }
    .loginTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 24px;
      background: linear-gradient(
        to right,
        red,
        orange,
        yellow,
        green,
        cyan,
        purple
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: hue 3s linear infinite;
      font-size: 25px;
      font-weight: 600;
      @keyframes hue {
        0% {
          filter: hue-rotate(0deg);
        }
        100% {
          filter: hue-rotate(360deg);
        }
      }
    }
    .btn {
      width: 100%;
    }
  }
  .el-footer {
    .box-card {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>